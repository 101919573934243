<template>
  <div class="apply2">
    <van-nav-bar
      title="订单列表"
      z-index="999"
      fixed
      placeholder
      style="width: 100%"
    >
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img
          src="../../../assets/other/back.png"
          style="width: 12px; height: 24px"
          @click="onClickLeft"
          alt=""
        />
      </template>
    </van-nav-bar>
    <div class="order-box" v-if="orderList.length > 0">
      <div
        class="order-cell"
        v-for="(item, i) in this.orderList"
        v-bind:key="i"
      >
        <div class="name-box">
          <span class="name">{{ item.name }}</span>
          <span class="mobile">{{ item.mobile }}</span>
          <span
            :class="item.payStatus == 0 ? 'status-dot red' : 'status-dot'"
          ></span>
          <span :class="item.payStatus == 0 ? 'status red' : 'status'">{{
            item.statusStr
          }}</span>
          <span class="last-time-box" v-if="item.canPay">
            <van-count-down :time="timeCompute(item.expireDate)">
              <template #default="timeData">
                <span class="time-text">{{ addZero(timeData.hours) }}:</span>
                <span class="time-text">{{ addZero(timeData.minutes) }}:</span>
                <span class="time-text">{{ addZero(timeData.seconds) }}</span>
              </template>
            </van-count-down>
          </span>
        </div>

        <div class="id-number">身份证号 {{ item.idNo }}</div>
        <div class="divider"></div>

        <div class="item-box">
          <div class="title">报考内容</div>
          <div class="class-name" @click="toDetail(item.classId)">
            {{ item.classTypeStr }}-{{ item.className }}
          </div>
        </div>

        <div class="item-box">
          <div class="title">学费金额</div>
          <div class="price">¥{{ item.payPrice }}</div>
        </div>
        <div class="item-box">
          <div class="title">订单号</div>
          <div class="class-grey">{{ item.id }}</div>
        </div>
        <div class="item-box">
          <div class="title">下单时间</div>
          <div class="class-grey">{{ item.createDate }}</div>
        </div>

        <div class="bottom-box">
          <a
            :href="'tel:' + item.kefuPhone"
            class="view-box"
            @click.stop="contactTeacher()"
          >
            <span class="iconfont icon-tel-img"></span>
            <!-- <img class="tel-img" src="../../../assets/other/tel-img.png" /> -->
            <span class="tel-teacher">专属老师({{ item.kefuName }})</span>
          </a>
          <span class="view-box" v-if="item.canPay">
            <span
              class="cancel-btn"
              v-if="item.payStatus == 0"
              @click.stop="cancelOrder(item.id)"
              >取消订单</span
            >
            <span
              class="pay-btn"
              v-if="item.payStatus == 0"
              @click.stop="toPayl2(item.classId, item.id)"
              >去支付</span
            >
          </span>
        </div>
      </div>
    </div>
    <van-empty description="暂无订单" v-if="orderList.length == 0" />
  </div>
</template>

<script>
import { Dialog, Empty, Icon, NavBar, CountDown } from "vant";
import { OrderCancel_Api, OrderList_Api } from "@/api/order";
import { getLocalStorage } from "@/core/utils/local-storage";
import { parse } from "qs";

export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Dialog.name]: Dialog,
    [Empty.name]: Empty,
    [CountDown.name]: CountDown,
  },

  computed: {
    timeCompute() {
      return function (value) {
        var startTime = value.replace(/-/g, "/");
        let date = new Date();
        let newValue = Date.parse(startTime) - Date.parse(date);
        // window.console.log(value);
        return newValue > 0 ? newValue : 0;
      };
    },
    addZero() {
      return function (value) {
        return value > 9 ? value : "0" + value;
      };
    },
  },

  data() {
    return {
      orderList: [],
    };
  },

  created() {
    this.getOrderList();
  },
  methods: {
    timeFinish() {
      this.getOrderList();
    },
    cancelOrder(id) {
      Dialog.confirm({
        title: "提示",
        message: "确认取消订单？",
      })
        .then(() => {
          this.cancelOrderPost(id);
        })
        .catch(() => {
          // on cancel
        });
    },

    contactTeacher() {},
    cancelOrderPost(id) {
      this.$reqPost(OrderCancel_Api + id, {}).then(() => {
        this.getOrderList();
      });
    },
    getOrderList() {
      let query = parse(getLocalStorage("query"));
      this.$reqGet(OrderList_Api, {
        ...query,
      }).then((res) => {
        this.orderList = res.map.items;
      });
    },

    toPayl2(id, orderId) {
      this.$router.push({
        name: "payOrder",
        query: { orderId: orderId, classId: id, itemId: id },
      });
    },

    toDetail(id) {
      this.$router.push({ name: "classDetail", params: { itemId: id } });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.apply2 {
  width: 100%;
  .order-box {
    width: 343px;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .order-cell {
      width: 343px;
      background: #ffffff;
      box-shadow: 0px 2px 12px 0px rgba(83, 16, 0, 0.05);
      border-radius: 8px;
      border: 1px solid #999999;
      padding-top: 21px;
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
      background-color: white;
      .name-box {
        display: flex;
        align-items: center;
        width: 100%;
      }
      .last-time-box {
        display: flex;
        align-items: center;
        color: #ff2525;
        margin-right: 10px;
        .time-text {
          color: #ff2525;
          font-size: 12px;
        }
      }
      .name {
        color: #333333;
        font-size: 16px;
        font-weight: 500;
        margin-left: 12px;
      }
      .mobile {
        color: #333333;
        margin-left: 8px;
        font-size: 14px;
        display: flex;
        flex: 1;
      }
      .status-dot {
        background-color: #333333;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        margin-right: 4px;
      }
      .status-dot.red {
        background-color: #ff2525;
      }
      .status {
        height: 24px;
        border-radius: 4px;
        margin-right: 12px;
        color: #333333;
        font-size: 12px;
        align-items: center;
        display: flex;
      }
      .status.red {
        color: #ff2525;
      }

      .id-number {
        display: flex;
        align-items: center;
        margin-left: 12px;
        margin-top: 12px;
        font-size: 12px;
        color: #666666;
      }

      .divider {
        margin-top: 12px;
        background-color: #e5e5e5;
        height: 0.5px;
        width: 100%;
      }
      .item-box {
        height: 44px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #333333;
          font-size: 14px;
          margin-left: 12px;
        }
        .class-name {
          margin-right: 12px;
          font-size: 12px;
          font-weight: 500;
          color: #333333;
        }
        .class-grey {
          color: #666666;
          margin-right: 12px;
          font-size: 12px;
        }
        .class-grey-time {
          color: #666666;
          font-size: 12px;
        }
        .price {
          color: #ff2525;
          font-size: 14px;
          margin-right: 12px;
        }
      }

      .bottom-box.active {
        justify-content: center;
      }
      .view-box {
        display: flex;
        align-items: center;
      }
      .bottom-box {
        height: 44px;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: space-between;
        .tel-img111 {
          width: 12px;
          height: 14px;
        }
        .iconfont {
          font-size: 11px;
          margin-left: 12px;
        }
        .tel-teacher {
          margin-left: 4px;
          font-size: 14px;
          color: var(--theme-color);
        }
        .cancel-btn {
          width: 72px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid #666666;
          font-size: 12px;
          color: #666666;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
        .pay-btn {
          width: 72px;
          height: 24px;
          border-radius: 4px;
          font-size: 12px;
          color: white;
          background-color: var(--theme-color);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
